<template>
    <div>
        <Navbar page="Condutas de mitigação" link="/cadastros" nameLink="Cadastros" link_2="/cadastros/riscos" nameLink_2="Gestão de riscos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4 flex flex-col gap-4 text-sm">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-12">
                                <label for="name" class="font-bold">
                                    Nome da conduta
                                </label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                        <div class="grid grid-cols-1 lg:grid-cols-12 gap-4 items-center">

                            <section class="col-span-1 lg:col-span-7 flex flex-col gap-1">
                                <label for="descricao" class="font-bold">
                                    Descrição
                                </label>
                                <input 
                                    v-model="form.descricao" 
                                    type="text" 
                                    name="descricao" 
                                    id="descricao"
                                    placeholder="xxxxxxxx"
                                    class="w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                                >
                            </section> 

                            <section class="col-span-1 lg:col-span-3 flex flex-col gap-1">
                                <label for="name" class="font-bold">
                                    Tipo da conduta
                                </label>
                                <select
                                    v-model="form.tipo" 
                                    name="tipo" 
                                    id="tipo" 
                                    class="w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                                    >
                                    <option :value="undefined" default>Escolha tipo</option>
                                    <option value="Organizacional">Administrativo (organizacional)</option>
                                    <option value="Técnica">Técnica</option>
                                </select>
                            </section>

                            <section class="col-span-1 lg:col-span-2 flex flex-col gap-1">
                                <label for="eficacia" class="font-bold">
                                    Eficácia (%)
                                </label>
                                <input 
                                    v-model="form.eficacia" 
                                    type="number" 
                                    name="eficacia" 
                                    id="eficacia"
                                    placeholder="60"
                                    class="w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                                >
                            </section>

                            <section class="col-span-1 lg:col-span-6 flex flex-col gap-1">
                                <div class="flex items-center">
                                    <label
                                        for="ameacas" 
                                        class="font-bold">
                                        Ameaças
                                    </label>
                                    <svg
                                        @click="abrirModalAmeacas()"
                                        fill="#059669"
                                        viewBox="0 0 256 256" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mx-1 h-6 inline text-green-600"
                                    >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                                <div class="flex">
                                    <multiselect
                                        v-model="form.ameacas"
                                        :options="ameacas"
                                        :multiple="true"
                                        :close-on-select="false"
                                        label="nome"
                                        trackBy="_id"
                                        placeholder="Selecione as ameaças"
                                        selectedLabel="selecionado"
                                        deselectLabel="Pressione Enter para remover"
                                        selectLabel="Pressione Enter para selecionar"
                                        class=""
                                        id="ameacas"
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
        <modal name="modalAmeacas" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova ameaça </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="novaAmeaca" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novaAmeaca.nome"
                            type="text"
                            name="novaAmeaca"
                            id="novaAmeaca"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                    <div class="py-3 text-left">
                        <button @click="$modal.hide('modalAmeacas')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="py-3 text-right">
                        <button @click="adicionarAmeaca" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'condutasmitigacao',
            ameacas: [],
            novaAmeaca:{
                nome: '',
            },
            form: {
                nome: '',
                descricao: '',
                tipo: undefined,
                ameacas: [],
                eficacia: null,
            },
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            if(!this.form.nome && !this.form.nome.trim()) return this.$vToastify.warning('Insira nome da conduta.');
            if(!this.form?.tipo) return this.$vToastify.warning('Escolha tipo da conduta');

            const copiaForm = JSON.parse(JSON.stringify(this.form));
            
            if(copiaForm.eficacia) copiaForm.eficacia = copiaForm.eficacia/100

            const req = await this.$http[method](`/v1/${this.route}`, copiaForm);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        abrirModalAmeacas(){
            this.novaAmeaca.nome = '';
            this.$modal.show('modalAmeacas')
        },
        async adicionarAmeaca() {
            const req = await this.$http.post(`/v1/ameacas`, this.novaAmeaca);
            if (req.data.success) {
                this.$vToastify.success("Ameaça adicionada com sucesso!");
                this.$modal.hide('modalAmeacas');

                this.ameacas.push(req.data.data);
                this.form.ameacas.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A Ameaça já existe`
                return this.$vToastify.error(error);
            }
            this.novoItem.nome = '';
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const ameacas = await this.$http.post(`/v1/ameacas/list`, { all: true});
        this.ameacas = ameacas.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(this.form.eficacia) this.form.eficacia = this.form.eficacia*100;
        }
    },
}
</script>